import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Component({
	selector: "user-commission-stats",
	templateUrl: "./user-commission-stats.component.html",
	styleUrls: ["./user-commission-stats.component.scss"],
})
export class UserCommissionStatsComponent implements OnInit {
	public teams: any = [];
	public totals: any = {};
	public error: string = null;

	constructor(private http: HttpClient, private change: ChangeDetectorRef) {
		this.recalculate();
	}

	ngOnInit() {
		this.loadUsers();
	}

	private loadUsers() {
		let url = "/api/stats/teams";
		this.error = null;
		this.http.get(url).subscribe(
			(data: any[]) => {
				this.teams = data;
				this.recalculate();
				this.change.detectChanges();
			},
			(error) => {
				this.error = error.error;
			}
		);
	}

	private recalculate() {
		this.totals = {
			budget: 0,
			earned: 0,
			forecast: 0,
			totalForecast: 0,
			itemsAvailable: 0,
			itemsRented: 0,
			itemsRentedThisYear: 0,
		};
		for (let t of this.teams) {
			t.totalForecast =
				parseInt(t.earned ?? 0) + parseInt(t.forecast ?? 0);
			t.percentage = this.getPercentage(t.totalForecast, t.budget);
			for (let u of t.users) {
				u.totalForecast =
					parseInt(u.earned ?? 0) + parseInt(u.forecast ?? 0);
				u.percentage = this.getPercentage(u.totalForecast, u.budget);
			}
			this.totals.budget += parseInt(t.budget ?? 0);
			this.totals.earned += parseInt(t.earned ?? 0);
			this.totals.forecast += parseInt(t.forecast ?? 0);
			this.totals.totalForecast += parseInt(t.totalForecast ?? 0);
		}
		this.totals.percentage = this.getPercentage(
			this.totals.totalForecast,
			this.totals.budget
		);
	}

	private getPercentage(forecast: number, budget: number) {
		if (forecast && budget) {
			return Math.round((100 * forecast) / budget) + " %";
		} else {
			return "";
		}
	}
}
