import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable({
	providedIn: "root",
})
export class UsersService {
	users: any[];
	me: any = null;
	todo: any = null;
	goal: any = null;

	constructor(private http: HttpClient) {
		this.loadUsers();
		this.loadMe();
	}

	loadUsers(query: any = {}): Promise<any[]> {
		return new Promise((resolve, reject) => {
			this.http.get("/api/users", { params: query }).subscribe(
				(data: any[]) => {
					this.users = data;
					resolve(this.users);
				},
				(error) => reject(error)
			);
		});
	}

	getUser(userId: number): Promise<any> {
		return new Promise((resolve, reject) => {
			this.http.get("/api/users/" + userId).subscribe(
				(data) => {
					resolve(data);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	loadMe(): Promise<any> {
		if (this.me) {
			return Promise.resolve(this.me);
		}
		return new Promise((resolve, reject) => {
			this.http.get("/api/users/me").subscribe(
				(data) => {
					this.me = data;
					resolve(this.me);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	loadTodo(): Promise<any> {
		if (this.todo) {
			return Promise.resolve(this.todo);
		}
		return new Promise((resolve, reject) => {
			this.http.get("/api/users/me/todo").subscribe(
				(data) => {
					this.todo = data;
					resolve(this.todo);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	loadGoal(): Promise<any> {
		if (this.goal) {
			return Promise.resolve(this.goal);
		}
		return new Promise((resolve, reject) => {
			this.http.get("/api/users/me/goal").subscribe(
				(data) => {
					this.goal = data;
					resolve(this.goal);
				},
				(error) => {
					reject(error);
				}
			);
		});
	}

	hasRole(role: string) {
		if (!this.me || !this.me.roles) {
			return false;
		}
		for (let r of this.me.roles) {
			if (r.role == role) {
				return true;
			}
		}
		return false;
	}

	async usersAsOptions(query: any = {}): Promise<any[]> {
		const users = await this.loadUsers(query);
		let options = [];
		for (let u of users) {
			options.push({ value: u.id, title: u.fullname });
		}
		return options;
	}

	all(): any[] {
		return this.users;
	}

	async setPicture(user: any, file: any): Promise<any[]> {
		return new Promise((resolve, reject) => {
			this.http
				.put("/api/users/" + user.id + "/picture", {
					hash: file.hash,
				})
				.subscribe(
					(data: any) => resolve(data),
					(error) => reject(error)
				);
		});
	}

	async setSignature(user: any, file: any): Promise<any[]> {
		return new Promise((resolve, reject) => {
			this.http
				.put("/api/users/" + user.id + "/signature", {
					hash: file.hash,
				})
				.subscribe(
					(data: any) => resolve(data),
					(error) => reject(error)
				);
		});
	}
}
