<mat-sidenav-container fullscreen>
	<mat-sidenav
		mode="side"
		#sidenav
		[mode]="mobile.matches ? 'over' : 'side'"
		[opened]="!mobile.matches"
		[fixedInViewport]="mobile.matches"
	>
		<div class="logotype">
			<img alt="logo" src="./assets/media/logos/logo-cream.svg" />
		</div>
		<mat-nav-list
			id="main-menu"
			(click)="mobile.matches && sidenav.close()"
		>
			<a
				(click)="
					openLink('https://sites.google.com/newproperty.se/info')
				"
				mat-list-item
				><i class="fas fa-fw fa-info-circle"></i> Info</a
			>
			<a
				mat-list-item
				*ngIf="users.hasRole('item_user')"
				[routerLink]="['/overview']"
				routerLinkActive="active"
				><i class="fas fa-fw fa-satellite"></i> Översikt</a
			>
			<a
				mat-list-item
				*ngIf="users.hasRole('item_user')"
				[routerLink]="['/items']"
				[queryParams]="{ state: 'new-available' }"
				routerLinkActive="active"
				><i class="fas fa-fw fa-building"></i> Objekt</a
			>
			<a
				mat-list-item
				class="sublink"
				*ngIf="users.hasRole('item_user')"
				[routerLink]="['/items']"
				[queryParams]="{
					user: users.me?.id || 0,
					state: 'new-available'
				}"
				routerLinkActive="active"
				><i class="fas fa-fw fa-empty"></i> Mina objekt</a
			>
			<a
				mat-list-item
				routerLink="/map"
				[queryParams]="{ state: 'available' }"
				routerLinkActive="active"
				><i class="fas fa-fw fa-map"></i> Karta</a
			>
			<a
				mat-list-item
				*ngIf="users.hasRole('item_user')"
				[routerLink]="['/prospects']"
				routerLinkActive="active"
				><i class="fas fa-fw fa-home"></i> Prospekt</a
			>
			<a
				mat-list-item
				class="sublink"
				*ngIf="users.hasRole('item_user')"
				[routerLink]="['/prospects']"
				[queryParams]="{ user: users.me?.id || 0 }"
				routerLinkActive="active"
				><i class="fas fa-fw fa-empty"></i> Mina prospekt</a
			>
			<a
				mat-list-item
				routerLink="/leads"
				[queryParams]="{ state: 'open' }"
				routerLinkActive="active"
				*ngIf="users.hasRole('item_user')"
				><i class="fas fa-fw fa-envelope"></i> Intressenter</a
			>
			<a
				mat-list-item
				class="sublink"
				*ngIf="users.hasRole('item_user')"
				[routerLink]="['/leads']"
				[queryParams]="{ user: users.me?.id || 0, state: 'open' }"
				routerLinkActive="active"
				><i class="fas fa-fw fa-empty"></i> Mina intressenter</a
			>
			<a
				mat-list-item
				routerLink="/invoices"
				[queryParams]="{ user: users.me?.id || 0, state: 'any' }"
				queryParamsHandling=""
				routerLinkActive="active"
				*ngIf="users.hasRole('item_user')"
				><i class="fas fa-fw fa-file"></i> Fakturor</a
			>
			<a
				mat-list-item
				[routerLink]="['/salary', users.me?.id || 0]"
				routerLinkActive="active"
				*ngIf="users.hasRole('item_user')"
				><i class="fas fa-fw fa-money-bill"></i> Min lön</a
			>
			<a
				mat-list-item
				routerLink="/salary/generate"
				routerLinkActive="active"
				*ngIf="users.hasRole('salary_admin')"
				><i class="fas fa-fw fa-money-bill"></i> Lönekörning</a
			>
			<a
				mat-list-item
				routerLink="/users"
				routerLinkActive="active"
				*ngIf="users.hasRole('user_admin')"
				><i class="fas fa-fw fa-users"></i> Användare</a
			>
			<a
				mat-list-item
				routerLink="/stats/users"
				routerLinkActive="active"
				*ngIf="users.hasRole('stats_users')"
				><i class="fas fa-fw fa-table"></i> Statistik</a
			>
			<a
				mat-list-item
				routerLink="/documents"
				routerLinkActive="active"
				*ngIf="users.hasRole('item_user')"
				><i class="fas fa-fw fa-folder"></i> Dokument</a
			>
			<a
				mat-list-item
				(click)="openLink('https://newproperty.pipedrive.com')"
				><i class="fas fa-fw fa-envelope"></i> Pipedrive</a
			>
			<a mat-list-item (click)="openLink('mailto:it@newproperty.se')"
				><i class="fas fa-fw fa-question-circle"></i> Support</a
			>
			<a mat-list-item routerLink="/auth/login" routerLinkActive="active"
				><i class="fas fa-fw fa-sign-out-alt"></i> Logga ut</a
			>
		</mat-nav-list>
		<div class="footer">
			<p *ngIf="error" class="test-version-notice">
				{{ error }}
			</p>
			<p *ngIf="testVersion" class="test-version-notice">
				<span class="badge badge-warning">Testversion</span>
			</p>
			<p>&copy;&nbsp;{{ today | date : "yyyy" }}&nbsp;Elmér Solutions</p>
		</div>
	</mat-sidenav>
	<mat-sidenav-content [class.is-mobile]="mobile.matches">
		<div class="main-menu-toggle" *ngIf="mobile.matches">
			<button (click)="sidenav.open()">
				<i class="fas fa-bars"></i>
			</button>
			<img
				alt="New Property"
				class="text-logo"
				src="./assets/media/logos/text-light.svg"
			/>
		</div>
		<div class="main-content">
			<router-outlet></router-outlet>
		</div>
	</mat-sidenav-content>
</mat-sidenav-container>
