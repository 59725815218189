<div class="page-padding">
	<div class="alert alert-warning" *ngIf="error">
		{{ error }}
	</div>

	<div class="alert alert-info" *ngIf="!users && !error">Laddar...</div>

	<mat-card>
		<mat-card-header>
			<mat-card-title>Användare</mat-card-title>
			<div class="card-header-toolbar right">
				<span class="badge badge-warning">Under utveckling</span>
			</div>
		</mat-card-header>
		<mat-card-content>
			<table class="table">
				<tr class="header">
					<td>E-post</td>
					<td>Namn</td>
				</tr>
				<tr *ngFor="let user of users">
					<td>{{ user.email }}</td>
					<td>
						<table-link path="users" [entity]="user"></table-link>
					</td>
				</tr>
			</table>
		</mat-card-content>
	</mat-card>
</div>
