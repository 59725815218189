<div class="wrapper" *ngIf="item">
	<span
		class="badge {{ getLabel().class }}"
		[matMenuTriggerFor]="changeMenu"
		>{{ getLabel().text }}</span
	>
	<span class="badge badge-danger" *ngIf="errorMessage">{{
		errorMessage
	}}</span>
	<mat-menu #changeMenu="matMenu">
		<button mat-menu-item (click)="setProbability(25)">
			&nbsp;&nbsp;0 - 50 %
		</button>
		<button mat-menu-item (click)="setProbability(50)">50 - 75 %</button>
		<button mat-menu-item (click)="setProbability(75)">75 - 100 %</button>
	</mat-menu>
</div>
