<item-assignment [item]="item"></item-assignment>

<item-invoice
	title="Uppstartsarvode"
	type="startup"
	[item]="item"
	*ngIf="item && item.assignments?.length > 0"
></item-invoice>

<div
	class="alert alert-secondary"
	*ngIf="item && item.assignments?.length == 0"
>
	Uppdragsavtal krävs för att kunna registrera arvoden.
</div>

<mat-card class="card-block item-users" *ngIf="item">
	<mat-card-header>
		<mat-card-title>Mäklare</mat-card-title>
		<div class="card-header-toolbar right">
			<select #newUserID (change)="addUser(newUserID.value)">
				<option>Lägg till</option>
				<option *ngFor="let u of users.all()" value="{{ u.id }}">
					{{ u.fullname }}
				</option>
			</select>
		</div>
	</mat-card-header>
	<mat-card-content>
		<div *ngFor="let c of item.contacts" class="user-wrapper">
			<div>
				<img
					src="{{ c.user.picture?.url }}"
					alt="Profilbild saknas"
					class="user-picture"
				/>
			</div>
			<div class="user-name">{{ c.user.fullname }}</div>
			<div class="user-email">
				<a href="mailto:{{ c.user.email }}">{{ c.user.email }}</a>
			</div>
			<p>
				<button (click)="removeUser(c.user)">Ta bort</button>
			</p>
		</div>
	</mat-card-content>
</mat-card>

<similar-items></similar-items>
