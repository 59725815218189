<a #downloadLink style="display: none" type="application/pdf"></a>
<div class="page-padding">
	<form-state [form]="loading" workingMessage="Laddar..."></form-state>

	<mat-card *ngIf="!loading.working">
		<mat-card-header>
			<mat-card-title
				>Lön
				<span *ngIf="!userService.hasRole('salary_admin')">
					{{ user.fullname }}
				</span>
				<span
					*ngIf="userService.hasRole('salary_admin')"
					style="padding-left: 0.5rem; padding-right: 0.5rem"
				>
					<user-select
						title="{{ user.fullname }}"
						btnClass="btn btn-secondary btn-font-lg btn-thin btn-sm"
						(change)="setUser($event)"
					></user-select>
				</span>
				<a [routerLink]="['.']" [queryParams]="{ year: year.year - 1 }"
					><i class="fas fa-fw fa-arrow-left"></i
				></a>
				<span>{{ year.year }}</span>
				<a [routerLink]="['.']" [queryParams]="{ year: year.year + 1 }"
					><i class="fas fa-fw fa-arrow-right"></i
				></a>
			</mat-card-title>
			<div class="card-header-toolbar right">
				<button
					*ngIf="year.salaryTotal - (year.salaryPaid || 0) > 0"
					class="btn btn-sm btn-primary"
					[disabled]="makePaymentForm.working"
					(click)="
						makePayment(
							year.year,
							year.salaryTotal - (year.salaryPaid || 0)
						)
					"
				>
					Betala
				</button>
			</div>
		</mat-card-header>
		<mat-card-content>
			<table class="table">
				<tr class="header">
					<td>Arvode Obetalt</td>
					<td>Arvode Betalt</td>
					<td>Provisionsgrundande</td>
					<td>Provision Mäklare</td>
					<td>Utbetalt</td>
					<td>Innestående</td>
				</tr>
				<tr>
					<td>{{ year.splitUnpaid | numberSv }} kr</td>
					<td>{{ year.splitPaid | numberSv }} kr</td>
					<td>{{ year.splitTotal | numberSv }} kr</td>
					<td>{{ year.salaryTotal | numberSv }} kr</td>
					<td>{{ year.salaryPaid | numberSv }} kr</td>
					<td>
						{{
							year.salaryTotal - (year.salaryPaid || 0) | numberSv
						}}
						kr
					</td>
				</tr>
			</table>
			<p>
				<form-state
					[form]="makePaymentForm"
					workingMessage="Sparar..."
				></form-state>
			</p>
		</mat-card-content>
	</mat-card>

	<mat-card>
		<mat-card-header>
			<mat-card-title>Utbetalningar</mat-card-title>
			<div class="card-header-toolbar right"></div>
		</mat-card-header>
		<mat-card-content>
			<table class="table">
				<tr class="header">
					<td>Löneår</td>
					<td>#</td>
					<td>Belopp</td>
					<td>Skickad till Fortnox</td>
					<td>&nbsp;</td>
				</tr>
				<tr *ngFor="let t of transactions">
					<td>{{ t.year }}</td>
					<td>{{ t.id }}</td>
					<td>{{ t.netAmount | numberSv }} kr</td>
					<td>{{ t.completed || "-" }}</td>
					<td class="download-button">
						<i
							class="fas fa-file-pdf clickable"
							*ngIf="
								t.invoiceGivenNumber &&
								!t.downloadWorking &&
								!t.PDFdownloadError
							"
							(click)="downloadPDF(t)"
						></i>
						<span
							class="badge badge-danger clickable"
							*ngIf="t.PDFdownloadError"
							(click)="t.PDFdownloadError = null"
							>{{ t.PDFdownloadError }}</span
						>
					</td>
				</tr>
			</table>
		</mat-card-content>
	</mat-card>

	<mat-card>
		<mat-card-header>
			<mat-card-title>Underlag</mat-card-title>
			<div class="card-header-toolbar right"></div>
		</mat-card-header>
		<mat-card-content>
			<table class="table">
				<tr class="header">
					<td>Faktura</td>
					<td>Avser</td>
					<td>Belopp</td>
					<td>Status</td>
					<td>Fördelad</td>
				</tr>
				<tr *ngFor="let s of splits">
					<td>
						<table-link
							path="invoices"
							[entity]="s.invoice"
						></table-link>
					</td>
					<td>
						<invoice-commissions
							[invoice]="s.invoice"
						></invoice-commissions>
					</td>
					<td>{{ s.amount | numberSv }} kr</td>
					<td class="invoice-state">
						<invoice-state-label
							[invoice]="s.invoice"
							(change)="change.detectChanges()"
						></invoice-state-label>
					</td>
					<td>
						<div *ngIf="s.completed">
							<div *ngIf="s.salaryBonus">
								<span class="badge badge-success">{{
									s.completed
								}}</span>
							</div>
							<div *ngIf="!s.salaryBonus">
								<span class="badge badge-warning"
									>Ej&nbsp;provision</span
								>
							</div>
						</div>
						<div *ngIf="!s.completed">
							<span class="badge badge-secondary">Utkast</span>
						</div>
					</td>
				</tr>
			</table>
		</mat-card-content>
	</mat-card>
</div>
