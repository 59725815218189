import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import {
	UntypedFormGroup,
	UntypedFormControl,
	Validators,
} from "@angular/forms";
import { ChangeDetectorRef } from "@angular/core";

import { FileService } from "core/files/files.service";
import { UsersService } from "core/users/users.service";
import { RequestForm } from "../../../partials/form-state/form-state.component";

@Component({
	selector: "kt-user-detail",
	templateUrl: "./user-detail.component.html",
	styleUrls: ["./user-detail.component.scss"],
})
export class UserDetailComponent implements OnInit {
	user: any;
	error = null;

	roleForm = new RequestForm(
		true,
		new UntypedFormGroup({
			role: new UntypedFormControl("", Validators.required),
		})
	);

	constructor(
		private route: ActivatedRoute,
		private http: HttpClient,
		private fileService: FileService,
		private userService: UsersService,
		private change: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.route.paramMap.subscribe((params) => {
			Promise.all([
				this.userService
					.getUser(parseInt(params.get("userID")))
					.then((user: any) => (this.user = user)),
			])
				.catch((error: any) => (this.error = "Fel: " + error.error))
				.then(() => {
					this.change.detectChanges();
				});
		});
	}

	uploadPicture(fileInput: any) {
		let file: File = fileInput.target.files[0];
		this.fileService
			.upload(file)
			.then((file: any) => this.userService.setPicture(this.user, file))
			.then((picture: any) => {
				this.user.picture = picture;
			})
			.catch((error: any) => {
				this.error = "Fel: " + error.error;
			})
			.then(() => this.change.detectChanges());
	}

	uploadSignature(fileInput: any) {
		let file: File = fileInput.target.files[0];
		this.fileService
			.upload(file)
			.then((file: any) => this.userService.setSignature(this.user, file))
			.then((signature: any) => {
				this.user.signature = signature;
			})
			.catch((error: any) => {
				this.error = "Fel: " + error.error;
			})
			.then(() => this.change.detectChanges());
	}
}
