<div class="page-padding">
	<div class="alert alert-warning" *ngIf="error">
		{{ error }}
	</div>

	<div class="alert alert-info" *ngIf="!teams && !error">Laddar...</div>

	<mat-card *ngIf="teams">
		<mat-card-header>
			<mat-card-title>Provision (tkr)</mat-card-title>
		</mat-card-header>
		<mat-card-content>
			<table class="table">
				<tr class="header">
					<td>Namn</td>
					<td>Fakturerat</td>
					<td>Pipeline</td>
					<td colspan="2">Prognos av budget</td>
					<td>Budget</td>
				</tr>
				<tbody *ngFor="let team of teams">
					<tr>
						<th>
							{{ team.name }}
						</th>
						<td>
							{{ team.earned | numberTkr }}
						</td>
						<td>
							{{ team.forecast | numberTkr : "" }}
						</td>
						<td>
							{{ team.totalForecast | numberTkr : "" }}
						</td>
						<td>{{ team.percentage }}</td>
						<td>
							{{ team.budget | numberTkr : "" }}
						</td>
					</tr>
					<tr *ngFor="let user of team.users">
						<td>
							<table-link
								path="users"
								[entity]="user"
							></table-link>
						</td>
						<td>
							{{ user.earned | numberTkr }}
						</td>
						<td>
							{{ user.forecast | numberTkr : "" }}
						</td>
						<td>
							{{ user.totalForecast | numberTkr : "" }}
						</td>
						<td>{{ user.percentage }}</td>
						<td>
							{{ user.budget | numberTkr : "" }}
						</td>
					</tr>
				</tbody>
				<tr class="totals">
					<td>Totalt</td>
					<td>{{ totals.earned | numberTkr }}</td>
					<td>{{ totals.forecast | numberTkr }}</td>
					<td>{{ totals.totalForecast | numberTkr }}</td>
					<td>{{ totals.percentage }}</td>
					<td>{{ totals.budget | numberTkr }}</td>
				</tr>
			</table>
		</mat-card-content>
	</mat-card>
</div>
