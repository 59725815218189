<div class="wrapper">
	<a [routerLink]="itemLink()" class="table-link">{{
		item.title || "[Namnlös]"
	}}</a>
	<span *ngIf="item.state == 'new'" class="badge badge-secondary" title="Ny"
		>N</span
	>
	<span
		*ngIf="item.state == 'rented'"
		class="badge badge-primary"
		title="Uthyrd"
		>U</span
	>
	<span
		*ngIf="item.state == 'withdrawn'"
		class="badge badge-dark"
		title="Återtagen"
		>Å</span
	>
</div>
